import {
    LinksContainerDPC,
    LinksDPC,
    AllegatoContainerDPC,
    AllegatoDPC,
    PageContainerDPC,
    PrintButtonDPC,
    CardHorizontalDPC
} from '@dpc-fe/shared';
import {graphql} from 'gatsby';
import React from 'react';
import DpcSidebar from "@dpc-fe/shared/components/dpc-sidebar";
import {getSlug, prop} from "../functions/functions";
import {buildMenu, findParent} from "@dpc-fe/shared/js/shared-functions";
import "moment/locale/it";

export default ({data, pageContext}) => {

    const language = '/' + pageContext.lang;
    const correlazioniTypes = ['comunicato_stampa','notizia','approfondimento','pubblicazione', 'corso', 'pagina', 'sezione'];

    const fotoVideoTypes = ['galleria_foto','galleria_flickr','video'];

    const correlazioni = data.node.relationships.field_correlazioni.filter(item => item.field_categoria_primaria == null || correlazioniTypes.indexOf(item.field_categoria_primaria) > -1).map(item => {
      return {
          title: (item.field_categoria_primaria ? '<b>' +item.field_categoria_primaria.charAt(0).toUpperCase() + item.field_categoria_primaria.replace(/_/g, ' ').slice(1)+'</b>' : '<b>Sezione</b>') + ' - ' + item.field_titolo_esteso,
          slug: getSlug(language + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name)
      }   
    });

    const fotoVideo = data.node.relationships.field_correlazioni.filter(item => fotoVideoTypes.indexOf(item.field_categoria_primaria) > -1).map(item => {
      return {
          title: (item.field_categoria_primaria === 'video' ? '<b>Video</b>' : '<b>Foto</b>') + ' - ' + item.field_titolo_esteso,
          slug: getSlug(language + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name)
      }   
    });

    const bandoDiGara = data.node.relationships.field_correlazioni.filter(item => item.field_categoria_primaria === 'bando_di_gara').map(item => {
      return {
          title: (item.field_categoria_primaria ? '<b>' +item.field_categoria_primaria.charAt(0).toUpperCase() + item.field_categoria_primaria.replace(/_/g, ' ').slice(1)+'</b>' : '') + ' - ' + item.field_titolo_esteso,
          slug: getSlug(language + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name)
      }   
    });

    const mappe = data.node.relationships.field_correlazioni.filter(item => item.field_categoria_primaria === 'mappa').map(item => {
      return {
          title: (item.field_categoria_primaria ? '<b>' +item.field_categoria_primaria.charAt(0).toUpperCase() + item.field_categoria_primaria.replace(/_/g, ' ').slice(1)+'</b>' : '') + ' - ' + item.field_titolo_esteso,
          slug: getSlug(language + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name)
      }   
    });

    const timeline = data.node.relationships.field_correlazioni.filter(item => item.field_categoria_primaria === 'timeline').map(item => {
      return {
          title: (item.field_categoria_primaria ? '<b>' +item.field_categoria_primaria.charAt(0).toUpperCase() + item.field_categoria_primaria.replace(/_/g, ' ').slice(1)+'</b>' : '') + ' - ' + item.field_titolo_esteso,
          slug: getSlug(language + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name)
      }   
    });

    const normative = data.node.relationships.field_normative.map(item => {
        return {
            title: item.field_titolo_esteso,
            slug: getSlug(language + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name)
        }
    });

    const linkEsterni = data.node.field_link_esterni ? data.node.field_link_esterni.map(item => {
        return {
            title: item.title,
            titleLinkEsterno: "Val al sito " + item.uri,
            slug: item.uri
        }
    }) : [];

    const fullmenu = buildMenu(pageContext.menu, pageContext.lang, process.env.ENV, null, data.node.drupal_internal__nid);
    const submenu = findParent(fullmenu, data.node.drupal_internal__nid, "idContenuto", "children");
    const showMenu = !!submenu && (!(Array.isArray(submenu.children) && submenu.children.length <= 0 && !submenu.idParent));

    return (
        <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                          menuItems={pageContext.menu}
                          slugTraduzione={data.node.relationships.field_riferimento_traduzione ? data.node.relationships.field_riferimento_traduzione.fields.slug : ''}
                          pageId={data.node.drupal_internal__nid}
                          title={data.node.title}
                          env={process.env.ENV}
                          lang={pageContext.lang}
                          immagineShare={data.node.relationships?.field_immagine_dettaglio ? process.env.PORTALE_URL + data.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL: process.env.PORTALE_URL + pageContext.socialImg?.relationships?.image?.localFile?.publicURL}
                          abstractShare={data.node.body?.processed ? data.node.body.processed.replace(/<\/?[^>]+(>|$)/g, "") : ''}
                          slug={process.env.PORTALE_URL + '/' + pageContext.lang + data.node.fields.slug}
                          minisiti={prop}
                          menuFooterItems={pageContext.menuFooter}
        >
            <div className="container">
                <div className="px-custom">
                    <div className="row">
                        <div className={`col-12${(submenu && submenu.children.length > 0) ? ' col-xl-8' : ''}`}>
                            <article>
                                <h1>{data.node.field_titolo_esteso}</h1>
                                {data.node.field_box_primo_piano_immagine ? 

                                    submenu && submenu.children.length > 0 ?                                
                                    <>
                                      
                                      <p>
                                          <img
                                              src={data?.node?.relationships?.field_immagine_anteprima?.relationships.image.localFile.childImageSharp.fluid.src}
                                              alt={data?.node?.relationships?.field_immagine_anteprima?.field_alt} className="img-fluid w-100"/>
                                      </p>
                                      <div className={"abstract-sez-minisito"}
                                          dangerouslySetInnerHTML={{__html: data?.node?.field_abstract?.processed}}/>
                                    </>
                                    :
                                    
                                    <CardHorizontalDPC                  
                                    body={data.node.field_abstract ? data.node.field_abstract.processed : ''}
                                    image={data?.node?.relationships?.field_immagine_anteprima}
                                    testoIntero={true}                    
                                />                   
                                  :null
                                }
                                <div
                                    dangerouslySetInnerHTML={{__html: data.node.body ? data.node.body.processed : ''}}/>
                                <section>
                                    {Array.isArray(data.node.relationships.field_allegati) && data.node.relationships.field_allegati.length > 0 &&
                                    <AllegatoContainerDPC titoloSezione={'Allegati'}>
                                        {data.node.relationships.field_allegati.map(allegato => (
                                            <AllegatoDPC
                                                  titolo={allegato.field_titolo_esteso}
                                                  descrizione={allegato.relationships?.field_allegato?.description}
                                                  dimension={allegato.relationships?.field_allegato?.filesize}
                                                  slug={allegato.relationships?.field_allegato?.localFile?.publicURL}
                                                  extension={allegato.relationships?.field_allegato?.localFile?.extension}
                                              />
                                        ))
                                        }
                                    </AllegatoContainerDPC>}

                                    {normative && normative.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Normativa'}>
                                        <LinksDPC links={normative}/>
                                    </LinksContainerDPC>}

                                    {bandoDiGara && bandoDiGara.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Avvisi e bandi di gara'}>
                                        <LinksDPC links={bandoDiGara}/>
                                    </LinksContainerDPC>}

                                    {correlazioni && correlazioni.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Per saperne di più'}>
                                        <LinksDPC links={correlazioni}/>
                                    </LinksContainerDPC>}

                                    {fotoVideo && fotoVideo.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Foto e video'}>
                                        <LinksDPC links={fotoVideo}/>
                                    </LinksContainerDPC>}                                  

                                    {mappe && mappe.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Mappe'}>
                                        <LinksDPC links={mappe}/>
                                    </LinksContainerDPC>}

                                    {timeline && timeline.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Timeline'}>
                                        <LinksDPC links={timeline}/>
                                    </LinksContainerDPC>}

                                    {linkEsterni && linkEsterni.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Link esterni'}>
                                        <LinksDPC links={linkEsterni}/>
                                    </LinksContainerDPC>}

                                </section>
                                <PrintButtonDPC/>
                            </article>
                        </div>
                        {showMenu &&
                        <div className="col-12 col-xl-4">
                            <DpcSidebar isPortale={true} menuItems={[submenu]}/>
                        </div>
                        }

                    </div>
                </div>
            </div>

        </PageContainerDPC>
    );
};

export const query = graphql`
query($slug: String!) {
  node: nodeSezioneSenzaAnticipazioni(fields: { slug: { eq: $slug } }) {
    field_categoria_primaria
    body {
      processed
    }
    field_abstract {
      processed
    }
    title
    field_titolo_esteso
    field_data
    drupal_internal__nid
    field_box_primo_piano_immagine
    field_link_esterni {
      title
      uri
    }
    fields {
      slug
    }
    relationships {
      field_immagine_dettaglio {
        field_alt
        field_didascalia
        relationships {
          image: field_immagine {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
      field_immagine_anteprima {
        field_alt
        field_didascalia
        relationships {
          image: field_immagine {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
      field_sottodominio {
        name
      }
      field_riferimento_traduzione {
        fields {
          slug
        }
      }

      field_allegati {
        title
        field_titolo_esteso
        relationships {
          field_sottodominio {
            name
          }
          field_allegato {
            filesize
            localFile {
              publicURL
              extension
            }
            filename
          }
        }
      }
      field_correlazioni {
        ...pubblicazioneFragment
        ...notiziaFragment
        ...approfondimentoFragment
        ...normativaFragment
        ...pageFragment
        ...bandoDiGaraFragment
        ...comunicatoStampaFragment
        ...corsoFragment
        ...mappaFragment
        ...timelineFragment
        ...sezioneConAnticipazioniFragment
        ...sezioneSenzaAnticipazioniFragment
        ...elencoApprofondimentiFragment
        ...elencoGlossariFragment
        ...elencoAttualitaFragment
        ...elencoPodcastFragment
        ...elencoPubblicazioniFragment
        ...elencoNormativaFragment
        ...elencoBandiEContrattiFragment
        ...elencoFotoVideoFragment
        ...homepageVolontariatoFragment
        ...homepageRelazioniFragment
        ...homepageRischiFragment
        ...homepageNazionaleFragment
        ...homepageMappeFragment
        ...homepageGiovaniFragment
        ...homepageFormazioneFragment
        ...homepageEventiFragment
        ...homepageEmergenzeFragment
        ...homepageDocsFragment
        ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
        ...bollettinoCriticitaFragment
        ...bollettinoVigilanzaFragment
        ...sezioneAllertaMeteoIdroFragment
        ...galleriaFotoFragment
        ...videoFragment
        ...galleriaFlickrFragment
      }
      field_normative {
        ...normativaFragment
      }
    }
    fields {
      slug
    }
  }
}
`;
